.card {
  margin: 10px auto;
  background: #ffffff;
  height: 450px;
  width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  color: #262626;
  font-size: 24px;
  font-weight: 400;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  /* animated interactivity */
  transition: 250ms;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}

/* when card is clicked adding .side class */
.side {
  /* background-color: #F7941E; */
  background-color: white;
  transform: rotateY(-180deg);
}

/* card content */
.card .front,
.card .back {
  position: absolute;
  padding: 1rem;
  backface-visibility: hidden;
}

.card .front {
  transform: rotateY(0deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 650px;
  height: 450px;
}

.card .front .question {
  /* font-size: 1.5rem; */
  /* font-weight: 700; */
  position: absolute;
  top: 4rem;
  color: #F7941E;
}

.answered {
  position: absolute;
  bottom: 0;
  right: 0.5em;
}

.card.side .front {
  opacity: 0;
  display: none;
}

.card .back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
}

.card small {
  opacity: 0.7;
  font-size: 0.7em;
  position: absolute;
  top: 2rem;
}

.card.side small {
  transform: rotateY(180deg);
}

.card small span {
  display: block;
  text-align: center;
  font-size: small;
}

.loading {
  color: white;
  width: 350px;
  text-align: center;
  margin: 20px auto;
}
.question-text {
  text-align: center;
}

.answer-text {
  text-align: center;
}

.card .back .answer {
  position: absolute;
  top: 4rem;
  color: white;
}
